import Parse from 'parse/node';
import axios from 'axios';
import config from '../../config';

export const parseGetBusinessCard = async ({ id }) => {
  try {
    return await Parse.Cloud.run('Redemption:getBusinessCard', {
      id,
    });
  } catch (e) {
    console.error(`Parse Error fetching business card: ${e}`);
    console.error(e);
    return null;
  }
};

// Business Card Functions
export const getVCards = async ({
  firstName,
  lastName,
  cellPhone,
  workPhone,
  businessName,
  position,
  note,
  workEmail,
  homeEmail,
  photo,
  twitter,
  facebook,
  instagram,
  linkedin,
  whatsapp,
  telegram,
}) => {
  try {
    const result = await axios.post(config.VCARD_URL, {
      firstName,
      lastName,
      businessName,
      cellPhone,
      workPhone,
      role: position,
      note,
      workEmail,
      homeEmail,
      photo,
      twitter,
      facebook,
      instagram,
      linkedin,
      whatsapp,
      telegram,
    });
    if (result.data === null || result.data === 'undefined') {
      throw new Error('Not Found');
    }
    return result.data;
  } catch (e) {
    console.error(`Error Creating VCARD: ${e}`);
    return null;
  }
};
