/* eslint-disable no-nested-ternary */

const dev = {
  PARSE_URL: 'https://api-dev.justspeakeasy.com/parse',
  VCARD_URL: 'https://api-dev.justspeakeasy.com/contact/vcard',
  PARSE_APP_ID: 'o0tjRPZnbwmurH37bznkfsrht9Rmdt4zPS7f4v2T',
  COOKIE_ID: "__tappShareDevId",
  MARKETING_URL: 'https://tappshare.io',
  PORT: 3000
};

const staging = {
  PARSE_URL: '###########################',
  VCARD_URL: '###########################',
  PARSE_APP_ID: '############################',
  COOKIE_ID: '__tappShareStagingId',
  MARKETING_URL: 'https://justspeakeasy.com',
};

const prod = {
  PARSE_URL: '###########################',
  VCARD_URL: '###########################',
  PARSE_APP_ID: '############################',
  COOKIE_ID: '__tappShareUserId',
  MARKETING_URL: 'https://justspeakeasy.com',
};

const config =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : prod;

export default {
  ...config,
};
