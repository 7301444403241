export default {
  primary: '#2085FF',
  primaryDark: '#0059cb',
  primaryLight: '#70b4ff',
  background: '#EFF3F7',
  font: '#4D5A77',
  white: '#FFFFFF',
  black: '#212121',
  error: '#F44336',
  lightGray: '#EEEEEE',
  lightBlueGray: '#ECEFF1',
  blueGray: '#607D8B',
  success: '#4caf50',
  iPhone: '#64B5F6',
  android: '#AED581',
  other: '#E0E0E0',
  darkGray: '#263238',
  gray: '#999999',
};
