import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPollResults } from '../redux/actions/premium_actions';

function usePollResults(couponId, cookieId, row, timestamp) {
  const [loading, setLoading] = useState(false);
  const [pollData, setPollData] = useState(null);
  const [initial, setInitial] = useState(true);
  const dispatch = useDispatch();

  const callback = (data) => {
    if (data) {
      setPollData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let timeout = 1;
    if (!initial) {
      timeout = 400;
    }
    setLoading(true);
    setInitial(false);
    setTimeout(() => {
      dispatch(
        getPollResults({
          couponId,
          cookieId,
          row,
          callback,
        }),
      );
    }, timeout);
  }, [timestamp]);

  return [pollData, loading];
}

export default usePollResults;
