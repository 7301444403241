import { combineReducers } from 'redux';
import standardReducer from './standard_reducer';
import businessCardReducer from './business_card_reducer';
import premiumReducer from './premium_reducer';
import sampleReducer from './sample_reducer';
import globalReducer from './global_reducer';

const appReducer = combineReducers({
  standardState: standardReducer,
  premiumState: premiumReducer,
  sampleState: sampleReducer,
  globalState: globalReducer,
  businessCardState: businessCardReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
