import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_STANDARD_CAMPAIGN,
  SET_STANDARD_CONVERSION,
  SET_STANDARD_METRICS,
} from '../constants/standard_constants';
import { setStandardCampaign } from '../actions/standard_actions';
import {
  parseGetStandardCampaign,
  parseSetStandardMetrics,
  parseSetStandardConversion,
} from '../queries/standard_queries';

function* getCampaign({ payload: { id } }) {
  try {
    const results = yield call(parseGetStandardCampaign, { id });
    if (!results) {
      throw new Error({ code: 404, message: 'Standard Campaign Not Found' });
    }
    yield put(
      setStandardCampaign({
        campaign: results.campaign,
        business: results.business,
      }),
    );
  } catch (e) {
    console.error(`Error fetching standard campaign`);
    yield put(setStandardCampaign({ campaign: null, business: null }));
    // TODO: Navigate to 404 Not found
  }
}

function* setStandardMetrics({ payload: { couponId, cookieId } }) {
  try {
    if (!couponId || !cookieId) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    yield call(parseSetStandardMetrics, { couponId, cookieId });
  } catch (e) {
    console.error(`Error setting standard metrics: ${e}`);
  }
}

function* setStandardConversions({ payload: { couponId, cookieId } }) {
  try {
    if (!couponId || !cookieId) {
      throw new Error({ code: 500, message: 'Invalid parameters' });
    }
    yield call(parseSetStandardConversion, { couponId, cookieId });
  } catch (e) {
    console.error(`Error setting standard conversion: ${e}`);
  }
}

function* standardSaga() {
  yield takeLatest(GET_STANDARD_CAMPAIGN, getCampaign);
  yield takeLatest(SET_STANDARD_CONVERSION, setStandardConversions);
  yield takeLatest(SET_STANDARD_METRICS, setStandardMetrics);
}

export default standardSaga;
