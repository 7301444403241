import Parse from 'parse/node';

export const parseGetPremiumCampaign = async ({ id }) => {
  try {
    return await Parse.Cloud.run('Redemption:getPremiumCampaign', {
      id,
    });
  } catch (e) {
    console.error(`Parse Error fetching premium campaign: ${e}`);
    return null;
  }
};

export const parseSetPremiumMetrics = async ({ couponId, cookieId }) => {
  try {
    return await Parse.Cloud.run('Redemption:setPremiumMetrics', {
      couponId,
      cookieId,
    });
  } catch (e) {
    console.error(`Parse Error setting premium metrics: ${e}`);
    return null;
  }
};

export const parseSetPremiumConversion = async ({
  couponId,
  cookieId,
  data,
  row,
}) => {
  try {
    return await Parse.Cloud.run('Redemption:setPremiumConversion', {
      couponId,
      cookieId,
      data,
      row,
    });
  } catch (e) {
    console.error(`Parse Error setting premium metrics: ${e}`);
    return null;
  }
};

export const parseGetPollResults = async ({ couponId, cookieId, row }) => {
  try {
    return await Parse.Cloud.run('Redemption:getPollResults', {
      couponId,
      cookieId,
      row,
    });
  } catch (e) {
    console.error(`Parse Error getting poll results: ${e}`);
    return null;
  }
};

export const parseGetFormResults = async ({ couponId, cookieId, row }) => {
  try {
    return await Parse.Cloud.run('Redemption:getFormResults', {
      couponId,
      cookieId,
      row,
    });
  } catch (e) {
    console.error(`Parse Error getting form results: ${e}`);
    return null;
  }
};
