import * as types from '../constants/sample_constants';

const INITIAL_STATE = {
  campaign: null,
};

const setCampaign = (state, action) => ({
  ...state,
  campaign: action.payload.campaign,
});

function sampleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SAMPLE_CAMPAIGN: {
      return setCampaign(state, action);
    }
    default: {
      return state;
    }
  }
}

export default sampleReducer;
